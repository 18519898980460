import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { SlideDown } from "react-slidedown";

import Expand from "../../../components/Expand/Expand";
import Paragraph from "../../../components/Paragraph/Paragraph";

const ClinicalUtility = (props) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <Row className="mt-3 main-container-padding">
      <Col>
        <Row>
          <Col md={11} sm={11}>
            <h2>Clinical Utility</h2>
            <hr className="title-border-bottom" />
          </Col>
          <Expand collapse={isVisible} setCollapse={setIsVisible} />
        </Row>

        <SlideDown transitionOnAppear={false}>
          {isVisible && (
            <Paragraph rowClasses="clinical-utility-animate">
              <p>
                The RMS is a brief screening instrument for bipolar
                I disorder. When a patient with a diagnosis of MDD has a positive screen on
                the RMS, a comprehensive evaluation by a healthcare professional
                is needed to confirm a diagnosis of bipolar I disorder.
              </p>
            </Paragraph>
          )}
        </SlideDown>
      </Col>
    </Row>
  );
};

export default ClinicalUtility;
