import { useContext } from "react";
import { Row, Col, Spinner } from "react-bootstrap";

import { GlobalContext } from "../../../state/GlobalState";

import { isIos } from "../../../helpers/isIos";

import classes from "./Speciality.module.css";

const Speciality = (props) => {
  const { state, dispatchActions } = useContext(GlobalContext);

  let selectClass = "";
  if (isIos()) {
    selectClass = classes.iosSelect;
  }

  const onChangeHandler = (e) => {
    if (e.target.value !== "") dispatchActions.setSpeciality(e.target.value);
  };

  return (
    <>
      <Row className="button-container mt-2 mb-2">
        <Col lg={6} md={6} sm={12} className="select-container">
          <select
            className={`form-select ${classes.hcpDropdown} ${selectClass}`}
            onChange={onChangeHandler}
            required
          >
            <option className={classes.optionBold} value="">
              Choose your specialty
            </option>
            <option
              className={classes.optionBold}
              value="Physician (Psychiatric)"
            >
              Physician (Psychiatric)
            </option>
            <option
              className={classes.optionBold}
              value="Nurse Practitioner (Psychiatric)"
            >
              Nurse Practitioner (Psychiatric)
            </option>
            <option
              className={classes.optionBold}
              value="Physician Assistant (Psychiatric)"
            >
              Physician Assistant (Psychiatric)
            </option>
            <option className={classes.optionBold} value="Physician (Family)">
              Physician (Family)
            </option>
            <option
              className={classes.optionBold}
              value="Nurse Practitioner (Family)"
            >
              Nurse Practitioner (Family)
            </option>
            <option
              className={classes.optionBold}
              value="Physician Assistant (Family)"
            >
              Physician Assistant (Family)
            </option>
            <option className={classes.optionBold} value="Pharmacist">
              Pharmacist
            </option>
            <option className={classes.optionBold} value="Physician (Other)">
              Physician (Other)
            </option>
            <option
              className={classes.optionBold}
              value="Nurse Practitioner (Other)"
            >
              Nurse Practitioner (Other)
            </option>
            <option
              className={classes.optionBold}
              value="Physician Assistant (Other)"
            >
              Physician Assistant (Other)
            </option>
          </select>
        </Col>
        <Col lg={6} md={6} sm={12}></Col>
      </Row>
      <Row>
        <Col className="text-center">
          {state.loading && <Spinner animation="border" variant="primary" />}
          {state.error && (
            <h6 className={`${classes.error}`}>{state.errorMessage}</h6>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Speciality;
