// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Banner_bannerImage__3a9w7{\n    width: 100%;\n}\n\n.Banner_mobileImg__3bFQX {\ndisplay: none;\n}\n\n.Banner_bannerContainer__2bYuS {\n    z-index: 9999;\n    margin-top: -6px;\n}\n\n@media only screen and (max-width: 768px) {\n    .Banner_mobileImg__3bFQX {\n        display: block;\n        width: 100%;\n    }\n\n    .Banner_desktopImg__3L4Mh {\n        display: none;\n    }\n}\n\n@media only print {\n    .Banner_Banner__1IRiH {\n        display: none;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Banner/Banner.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;AACA,aAAa;AACb;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI;QACI,cAAc;QACd,WAAW;IACf;;IAEA;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".bannerImage{\n    width: 100%;\n}\n\n.mobileImg {\ndisplay: none;\n}\n\n.bannerContainer {\n    z-index: 9999;\n    margin-top: -6px;\n}\n\n@media only screen and (max-width: 768px) {\n    .mobileImg {\n        display: block;\n        width: 100%;\n    }\n\n    .desktopImg {\n        display: none;\n    }\n}\n\n@media only print {\n    .Banner {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bannerImage": "Banner_bannerImage__3a9w7",
	"mobileImg": "Banner_mobileImg__3bFQX",
	"bannerContainer": "Banner_bannerContainer__2bYuS",
	"desktopImg": "Banner_desktopImg__3L4Mh",
	"Banner": "Banner_Banner__1IRiH"
};
export default ___CSS_LOADER_EXPORT___;
