import React, { Suspense, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import LoginForm from "../../components/LoginForm/LoginForm";
import Header from "../../components/Navigation/Header/Header";
import Router from "../../router/Router";
import Footer from "../../components/Navigation/Footer/Footer";
import HonestyBox from "../../components/HonestyBox/HonestyBox";
// import Cookie from "../../components/Cookie/Cookie";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Loading from "../../components/Loading/Loading";

import { GlobalContext } from "../../state/GlobalState";

const Layout = (props) => {
  const { state } = useContext(GlobalContext);

  useEffect(() => {
    if (state.isHcp) {
      document.getElementById("root").classList.remove("no-hcp");
    } else {
      document.getElementById("root").classList.add("no-hcp");
    }
  }, [state]);

  return (
    <>
      {!!state.isLoggedIn ? (
        <>
          {/* {!state.cookieEnabled && <Cookie />} */}
          {!state.isHcp && <HonestyBox />}

          <header className="header">
            <Header />
          </header>

          <main className={state.cookieEnabled ? "relative" : ""}>
            <Container>
              <Suspense fallback={<Loading />}>
                <ScrollToTop />
                <Router />
              </Suspense>
            </Container>
          </main>

          <br />

          <footer className="footer">
            <Footer />
          </footer>
        </>
      ) : (
        <LoginForm />
      )}
    </>
  );
};

export default withRouter(Layout);
