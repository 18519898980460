import { setCookie } from "../helpers/Cookie";

const GlobalReducer = (state, action) => {
  switch (action.type) {
    case "ADD_HCP":
      if (
        setCookie({
          key: "isHcp",
          value: true,
          expiry: +process.env.REACT_APP_COOKIE_EXPIRY,
        })
      ) {
        return {
          ...state,
          isHcp: true,
          error: false,
          loading: false,
        };
      }
      return state;

    case "ENABLE_COOKIE":
      if (
        setCookie({
          key: "cookieEnabled",
          value: true,
          expiry: +process.env.REACT_APP_COOKIE_EXPIRY,
        })
      ) {
        return {
          ...state,
          cookieEnabled: true,
        };
      }
      return state;

    case "SET_LOADER":
      return {
        ...state,
        loading: true,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: true,
        errorMessage: action.message,
        loading: false,
      };
    case "LOGIN_USER":
      setCookie({
        key: "_log_rms_in",
        value: action.userName,
        expiry: 1,
      });
      return {
        ...state,
        error: false,
        loading: false,
        isLoggedIn: action.userName,
      };
    case "LOGIN_FAIL":
      return {
        ...state,
        error: true,
        loading: false,
        errorMessage: action.message,
      };
    default:
      return state;
  }
};

export default GlobalReducer;
