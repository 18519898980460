/**
 * 
 * @param {*} cookieKey 
 * Function to check whether cookie is exists or not
 */
 export const cookieExist = (cookieKey) => {
  try {
    if (
      document.cookie
        .split(";")
        .some((item) => item.trim().startsWith(`${cookieKey}=`))
    ) {
      return true;
    }
  } catch (err) {
    console.error(`Could not get the ${cookieKey} cookie  ===> ${err} `);
  }
  return false;
};

/**
 * 
 * @param {*} param0 
 * Sets the cookie with default path as origin
 * expiry should be provided in days
 * return boolean [true/false]
 */
export const setCookie = ({ key, value, path = "/", expiry }) => {
  try {
    // key and value are two mandatory
    if (!key) {
      return false;
    } else {
      let cookieString = `${key}=${value}`;

      cookieString += `; path= ${path}`;

      if (expiry && typeof expiry === "number") {
        cookieString += `; expires = ${getUTCDate(expiry)};secure`;
      }
      document.cookie = cookieString;
      return true;
    }
  } catch (err) {
    console.error(`could not the set the cookie with ${key} = ${value}`);
  }
  return false;
};

/**
 * 
 * @param {*} keyName
 * Function to get the cookie value 
 */
export const getCookie = (keyName) => {
  var cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    var [currentKey, value] = cookie.split("=");
    if (currentKey === keyName) {
      return value;
    }
  }
  return null;
};

/**
 * 
 * @param {*} daysToAdd 
 * will return the UTC string representation of date with provided offset
 */
 const getUTCDate = (daysToAdd) => {
  try {
    var now = new Date();
    var time = now.getTime();
    var expireTime = time + 1000 * (60 * 60 * 24 * daysToAdd);
    now.setTime(expireTime);
    return now.toUTCString();
  } catch (err) {
    console.error(`Error while creating UTC string date ${err}`);
  }
  return new Date(Date.now()).toUTCString();
};