import { useRef, useEffect } from "react";
import useOnScreen from "../../hooks/useOnScreen";

/**
 * RFC for animation with respect to view port
 * @param {*} props
 * @returns
 */
const AnimationVideo = (props) => {
  const ref = useRef();
  const inViewport = useOnScreen(ref);

  // check the element is in view port or not
  useEffect(() => {
    if (inViewport) {
      document
        .getElementById(props.id)
        .play()
        .catch((err) => console.log(err));
    }
  }, [inViewport, props.id]);

  return (
    <video
      className={`${props.className}`}
      width={props.width}
      id={props.id}
      ref={ref}
      muted
      onClick={props.showImageInModal}
      preload="metadata"
      playsInline
    >
      <source src={props.video} type="video/mp4" />
    </video>
  );
};

export default AnimationVideo;
