import { useEffect } from "react";
import Aos from "aos";
import { Row, Col, Table } from "react-bootstrap";
import Paragraph from "../../../components/Paragraph/Paragraph";

import "aos/dist/aos.css";
import classes from "./PredictiveTable.module.css";

const PredicitveTable = (props) => {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <Row className="main-container-padding">
      <Col>
        <Row className={classes.tableContainer}>
          <Col>
            <Table>
              <thead>
                <tr data-aos="fade-up">
                  <th>Threshold</th>
                  <th>Sensitivity</th>
                  <th>Specificity</th>
                  <th>PPV</th>
                  <th>NPV</th>
                  <th>
                    Percentage (%) positive <br />
                    correctly predicted events
                  </th>
                </tr>
              </thead>
              <tbody id="predictive-table">
                <tr data-aos="fade-up">
                  <td>0</td>
                  <td>1</td>
                  <td>0</td>
                  <td>0.48</td>
                  <td>0</td>
                  <td>47.54</td>
                </tr>
                <tr data-aos="fade-up">
                  <td>1</td>
                  <td>1</td>
                  <td>0.13</td>
                  <td>0.51</td>
                  <td>1</td>
                  <td>54.10</td>
                </tr>
                <tr data-aos="fade-up">
                  <td>2</td>
                  <td>0.98</td>
                  <td>0.33</td>
                  <td>0.57</td>
                  <td>0.95</td>
                  <td>63.93</td>
                </tr>
                <tr data-aos="fade-up">
                  <td>3</td>
                  <td>0.97</td>
                  <td>0.59</td>
                  <td>0.68</td>
                  <td>0.95</td>
                  <td>77.05</td>
                </tr>
                <tr data-aos="fade-up">
                  <td>4</td>
                  <td>0.88</td>
                  <td>0.80</td>
                  <td>0.80</td>
                  <td>0.88</td>
                  <td>83.61</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Paragraph>
          <p className={classes.tableText}>
            *NPV, negative predictive value; PPV, positive predictive value.
          </p>
        </Paragraph>
      </Col>
    </Row>
  );
};

export default PredicitveTable;
