import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { SlideDown } from "react-slidedown";

import Expand from "../../../components/Expand/Expand";
import AnimationVideo from "../../../components/AnimationVideo/AnimationVideo";

import { MOBILE_VIEW } from "../../../config/config";

import classes from "./Diagnosis.module.css";

import diagnosisVideo from "../../../assets/videos/diagnosis.mp4";
import diagnosisImage from "../../../assets/images/bipolar_missed_diagnosis_mobile.png";

const Diagnosis = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth <= MOBILE_VIEW
  );

  useEffect(() => {
    const updateMobileView = () => {
      setIsMobileView(window.innerWidth <= MOBILE_VIEW);
    };

    window.addEventListener("resize", updateMobileView);

    return () => window.removeEventListener("resize", updateMobileView);
  }, []);

  return (
    <Row className="mt-2 main-container-padding">
      <Col>
        <Row>
          <Col lg={11} md={11} sm={11}>
            <h2>Potential Consequences of Missed Bipolar I Diagnosis</h2>
            <hr className="title-border-bottom" />
          </Col>

          <Expand collapse={isVisible} setCollapse={setIsVisible} />
        </Row>

        <SlideDown transitionOnAppear={false}>
          {isVisible && (
            <Row className="diagnosis-animate mt-2">
              <Col>
                <Row>
                  <Col lg={5} md={5} sm={12}>
                    <p>
                      Diagnostic delays can be associated with poor clinical
                      outcomes.
                      <sup>7,8</sup>
                    </p>
                    <p>
                      Importantly, early treatment of bipolar disorder is
                      reported to improve outcomes across a range of areas
                      including symptomatic response, episode duration, and psychosocial functioning.
                      <sup>8,9</sup> Identifying clinical characteristics that
                      are more likely related to bipolar depression than to MDD
                      can help differentiate the disorders and reduce
                      misdiagnosis.
                      <sup>10,11</sup> Using the RMS may help clinicians
                      identify patients with depressive symptoms who have been
                      diagnosed with MDD and warrant more comprehensive
                      evaluation for bipolar I disorder by their healthcare
                      provider.
                    </p>
                  </Col>
                  <Col
                    lg={7}
                    md={7}
                    sm={12}
                    className={`text-center ${classes.parent}`}
                  >
                    {isMobileView ? (
                      <img
                        src={diagnosisImage}
                        alt="Potential Consequences of Missed Bipolar Diagnosis"
                        width="90%"
                      />
                    ) : (
                      <AnimationVideo
                        className=""
                        width="90%"
                        id="diagnosis-video"
                        showImageInModal={() => {}}
                        video={diagnosisVideo}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </SlideDown>
      </Col>
    </Row>
  );
};

export default Diagnosis;
