import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";

import Banner from "../../components/Banner/Banner";
import References from "../../components/References/References";
import Paragraph from "../../components/Paragraph/Paragraph";
import Details from "./Details/Details";
import PredicitveTable from "./PredicitveTable/PredicitveTable";
import Characteristics from "./Characteristics/Characteristics";
import PhaseCard from "../../components/PhaseCard/PhaseCard";

import banner from "../../assets/images/validation_banner.jpg";
import mobileBanner from "../../assets/images/validation_mobile.jpg";
import phase3 from "../../assets/images/phase3.png";

import classes from "./Validation.module.css";

const Validation = () => {
  const [showPhasePopup, setShowPhasePopup] = useState(false);

  useEffect(() => {
    document.title = "RMS | Validation";
  });

  const showPhaseInModal = () => {
    setShowPhasePopup(!showPhasePopup);
  };

  return (
    <Row>
      <Col>
        <Banner banner={banner} mobileBanner={mobileBanner} />

        <Row className="margin-to-banner main-container-padding">
          <Col lg={5} md={5} sm={12} className="text-center position-relative">
            {showPhasePopup && (
              <PhaseCard clicked={showPhaseInModal} phaseNumber="3">
                <h6 className="phase-heading">
                  Quantitative Evaluation (Validation) 
                </h6>
                <ul>
                  <li>Observational study conducted with individuals with MDD or 
                    bipolar I disorder (n = 139)
                  </li>
                  <li>
                    Draft 10-item bipolar I disorder screening tool, CES-D, and MDQ administered
                  </li>
                  <li>
                    Combination of item sets were simultaneously evaluated by the development team 
                    to optimize scoring and test characteristics in as few items as necessary
                  </li>
                </ul>
              </PhaseCard>
            )}

            <div>
              <img
                className="phase-image pl-4 width-phase3"
                src={phase3}
                alt="phase3"
                onClick={showPhaseInModal}
              />
              {!showPhasePopup && (
                <span
                  className={`${classes.phaseCircle1}`}
                  onClick={showPhaseInModal}
                >
                  <span className={`${classes.phaseText3}`}>Phase</span> <br />
                  <span className={`${classes.phaseNumber3}`}>3</span>
                </span>
              )}
              <span className={`${classes.circle2}`}></span>
              <span className={`${classes.circle3}`}></span>
            </div>
          </Col>
          <Col lg={7} md={7} sm={12}>
            <Details />
          </Col>
        </Row>

        <Row className="main-container-padding">
          <Col>
            <Paragraph rowClasses="mt-2">
              <p className="phase-info">
                *BP-I, bipolar I disorder; MDD, major depressive disorder; MDQ,
                Mood Disorder Questionnaire; MINI, Mini International
                Neuropsychiatric Interview.
              </p>
            </Paragraph>
          </Col>
        </Row>

        <Paragraph rowClasses="mt-2 main-container-padding">
          <p className="mb-3">
            Based on the responses to the draft screening tool, the development
            team selected the combination of items that would collectively
            provide the best balance of specificity and sensitivity while using
            the smallest possible number of items. Six final RMS items, 3 that
            screen for depression symptoms that are characteristic of bipolar I
            disorder and 3 that screen for manic symptoms, were chosen. YES
            responses on 4 or more RMS items had the highest estimated accuracy
            for identifying participants with bipolar I disorder. Three or more
            YES responses also signaled a higher likelihood for bipolar I
            disorder than for MDD. When a respondent has a positive screen, a
            comprehensive follow-up evaluation by a healthcare provider is
            needed to confirm a diagnosis of bipolar I disorder.
          </p>
        </Paragraph>

        <PredicitveTable />

        <Characteristics>
          <Paragraph rowClasses="mr-1 mt-0">
            <div className="d-flex">
              <p className={`${classes.tableSubScript}`}>
                <sup>a</sup>&nbsp;
              </p>
              <p className={`${classes.tableText}`}>
                To screen positive for possible bipolar disorder on the RMS,
                "Yes" is endorsed for 4 or more items.
              </p>
            </div>
            <div className={`d-flex ${classes.tableDetailsMarginTop}`}>
              <p className={`${classes.tableSubScript}`}>
                <sup>b</sup>&nbsp;
              </p>
              <p className={`${classes.tableText}`}>
                To screen positive for possible bipolar disorder on the MDQ, 3
                criteria must be met: "Yes" to 7 or more of the 13 items in
                Question 1 AND "Yes" to Question 2 AND "Moderate Problem" of
                "Serious Problem" Question 3.
              </p>
            </div>
          </Paragraph>
        </Characteristics>

        <References>
          <li>
            McIntyre RS, Patel MD, Masand PS, et al. The Rapid Mood Screener
            (RMS): A novel and pragmatic screener for Bipolar I disorder.{" "}
            <i>Curr Med Res Opin</i>. 2021;37(1):135<span>&ndash;</span>144.
          </li>
          <li>
            Hirschfeld RM, Williams JB, Spitzer RL, et al. Development and
            validation of a screening instrument for bipolar spectrum disorder:
            the Mood Disorder Questionnaire. <i>Am J Psychiatry</i>.
            2000;157(11):1873<span>&ndash;</span>1875.
          </li>
        </References>
      </Col>
    </Row>
  );
};

export default Validation;
