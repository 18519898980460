import { Row, Button } from "react-bootstrap";

const Action = (props) => {
  const confirmHandler = () => {
    if (props.toUrl) {
      window.open(props.toUrl);
      props.back(false);
    } else {
      window.open("https://www.abbvie.com/");
    }
  };

  const backHandler = () => {
    if (props.toUrl) {
      props.back(false);
    } else {
      props.back(null);
    }
  };

  return (
    <Row className="container button-container">
      <Button
        className="action-btn hcp-confirm"
        variant="light"
        onClick={backHandler}
      >
        Back
      </Button>
      <Button
        className="action-btn hcp-confirm"
        variant="light"
        onClick={confirmHandler}
      >
        Confirm
      </Button>
    </Row>
  );
};

export default Action;
