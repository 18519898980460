import Backdrop from "../Backdrop/Backdrop";
import Navigator from "../Navigation/Navigator/Navigator";
import Logo from "../Navigation/Logo/Logo";

import "./SideDrawer.css";

/**
 * RFC for Mobile side drawer menu
 * @param {*} props
 * @returns <JSX />
 */
const SideDrawer = (props) => {
  const classState = ["SideDrawer", props.sideDrawer ? "Open" : "Close"];

  return (
    <>
      <Backdrop show={props.sideDrawer} clicked={props.setSideDrawer} />

      <div className={classState.join(" ")}>
        <div className="logo-mobile-view">
          <Logo clicked={props.setSideDrawer} />
        </div>
        <div className="nav-mobile-view">
          <Navigator clicked={props.setSideDrawer} />
        </div>
      </div>
    </>
  );
};

export default SideDrawer;
