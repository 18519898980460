import { Row, Col, Button } from "react-bootstrap";

// import Download from "../Download/Download";

import classes from "./RMSActions.module.css";

/**
 * RFC for creating action like download, rest form and submit form of RMS question
 * @param {*} props
 * @returns <JSX />
 */
const RMSActions = (props) => {
  return (
    <Row className={`mt-3 mb-1`}>
      <Col lg={6} md={6} sm={12} className="float-left">
        {/* <Download className="mx-0" pdfName="Rapid_Mood_Screener_Questionaire" /> */}
      </Col>
      <Col lg={6} md={6} sm={12} className="float-right mt-2">
        <Row>
          <Col lg={6} md={6} sm={12} className="button-right">
            {props.isFormTouched && (
              <Button
                variant=""
                className={`btn active mr-2 ${classes.buttonSubmitResult} ${classes.rmsActions}`}
                onClick={props.resetForm}
              >
                Reset All
              </Button>
            )}
          </Col>
          <Col lg={6} md={6} sm={12} className="button-right">
            <Button
              variant=""
              className={`btn active submit ${classes.buttonSubmitResult} ${classes.rmsActions}`}
              onClick={props.submitted}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default RMSActions;
