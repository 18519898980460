import { Button, Row, Col } from "react-bootstrap";
import Speciality from "../Speciality/Speciality";

const Footer = (props) => {
  return (
    <>
      <div className="container-fluid">
        <Row className="button-container mt-2">
          <Col lg={6} md={6} sm={12}>
            <Button
              variant="light"
              className="action-btn hcp-button"
              onClick={() => {
                props.setIAmHealthcare(true);
              }}
            >
              YES, I am US healthcare professional
            </Button>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Button
              variant="light"
              className="action-btn hcp-button"
              onClick={() => {
                props.setIAmHealthcare(false);
              }}
            >
              No, I am not US healthcare professional
            </Button>
          </Col>
        </Row>
      </div>
      <div className="container-fluid">
        {props.iAmHealthcare && <Speciality setIsHcp={props.setIsHcp} />}
      </div>
    </>
  );
};

export default Footer;
