import Popup from "../Popup/Popup";

import "./Video.css";

/**
 * RFC for vimeo video player
 * @param {*} props
 * @returns <JSX />
 */
const Video = (props) => {
  const body = (
    <div id="iframe_container" className="iframe-container">
      <iframe
        id="1"
        name="video_player"
        title="Video"
        src="https://player.vimeo.com/video/344399007"
        align="center"
        scrolling="yes"
        allowFullScreen
        className="iframe-responsive"
      ></iframe>
    </div>
  );

  return (
    <Popup
      header=""
      body={body}
      footer=""
      show={props.showVideo}
      keyboard={false}
      backdrop={true}
      closeModal={props.setShowVideo}
      closeButton={true}
    />
  );
};

export default Video;
