import { NavLink } from "react-router-dom";
import { Button } from "react-bootstrap";
import Paragraph from "../Paragraph/Paragraph";

import classes from "./NotFound.module.css";

/**
 * RFC for page not found [404]
 * @returns <JSX />
 */
const NotFound = () => {
  return (
    <Paragraph colClasses={`text-center ${classes.verticalAlign}`}>
      <h1>Page Not Found</h1>
      <p className="text-center">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
        eiusmod.
      </p>

      <Button variant="primary" className={classes.nfBtn}>
        <NavLink
          id="nav-link-home-page"
          className={classes.navLink}
          activeClassName="active"
          to="/"
        >
          Go to homepage
        </NavLink>
      </Button>
    </Paragraph>
  );
};

export default NotFound;
