const PhaseCard = (props) => {
  return (
    <div
      className={
        props.phaseNumber === 2
          ? "card card-width phase2-alignment"
          : "card card-width"
      }
    >
      <div className="card-body">
        <button type="button" className="close" onClick={props.clicked}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <div className="phase-circle text-center">
          <span className="phase-text">Phase</span>
          <br />
          <span className="phase-number">{props.phaseNumber}</span>
        </div>
        <div className="container mt-5 text-left">{props.children}</div>
      </div>
    </div>
  );
};

export default PhaseCard;
