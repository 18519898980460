import React from "react";

const BotTag = () => (
  <div>
    <a
      href={process.env.REACT_APP_BOT_PATH}
      rel="nofollow"
      style={{ display: "none" }}
      aria-hidden="true"
    >
      honeypot link
    </a>
  </div>
);

export default BotTag;
