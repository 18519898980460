// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Logo_desktopLogo__3MpP_ {\n    width: 46%;\n}\n.Logo_mobileLogo__3352m {\n    display: none;\n}\n\n@media only screen and (min-width: 320px) and (max-width: 768px) {\n    .Logo_mobileLogo__3352m {\n        display: block;\n        width: 60%;\n        margin-left: 90px;\n    }\n    .Logo_desktopLogo__3MpP_ {\n        display: none;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/Navigation/Logo/Logo.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;AACA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,cAAc;QACd,UAAU;QACV,iBAAiB;IACrB;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".desktopLogo {\n    width: 46%;\n}\n.mobileLogo {\n    display: none;\n}\n\n@media only screen and (min-width: 320px) and (max-width: 768px) {\n    .mobileLogo {\n        display: block;\n        width: 60%;\n        margin-left: 90px;\n    }\n    .desktopLogo {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktopLogo": "Logo_desktopLogo__3MpP_",
	"mobileLogo": "Logo_mobileLogo__3352m"
};
export default ___CSS_LOADER_EXPORT___;
