import { Row, Col } from "react-bootstrap";

import classes from "./Loading.module.css";

/**
 * RFC for page loader
 * @returns
 */
const Loading = () => (
  <Row>
    <Col className={classes.viewPortHeight}>
      <div className={classes.loader}>Loading...</div>
    </Col>
  </Row>
);

export default Loading;
