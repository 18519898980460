import React from "react";
import { Route, Switch } from "react-router-dom";

import About from "../containers/About/About";
import BipolarDisorder from "../containers/BiploarDisorder/BiploarDisorder";
import Development from "../containers/Development/Development";
import Validation from "../containers/Validation/Validation";
import RMS from "../containers/RMS/RMS";
import ClinicianGuide from "../containers/ClinicianGuide/ClinicianGuide";
import NotFound from "../components/NotFound/NotFound";
// const BipolarDisorder = React.lazy(() =>
//   import("../containers/BiploarDisorder/BiploarDisorder")
// );
// const Development = React.lazy(() =>
//   import("../containers/Development/Development")
// );
// const Validation = React.lazy(() =>
//   import("../containers/Validation/Validation")
// );
// const RMS = React.lazy(() => import("../containers/RMS/RMS"));
// const ClinicianGuide = React.lazy(() =>
//   import("../containers/ClinicianGuide/ClinicianGuide")
// );
// const NotFound = React.lazy(() => import("../components/NotFound/NotFound"));

const Router = () => {
  return (
    <Switch>
      <Route path="/" exact component={About} />
      <Route path="/about" exact component={About} />
      <Route path="/bipolar-disorder" exact component={BipolarDisorder} />
      <Route path="/development" exact component={Development} />
      <Route path="/validation" exact component={Validation} />
      <Route path="/rms" exact component={RMS} />
      <Route path="/clinician-guide" exact component={ClinicianGuide} />
      <Route render={() => <NotFound />} />
    </Switch>
  );
};

export default Router;
