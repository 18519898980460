import React from "react";
import { BrowserRouter } from "react-router-dom";

import { GlobalState } from "./state/GlobalState";
import Layout from "./hoc/Layout/Layout";

function App() {
  return (
    <GlobalState>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </GlobalState>
  );
}

export default App;
