import classes from "./Expand.module.css";

/**
 * RFC for toggle between expand and collapse
 * @param {*} props
 * @returns <JSX />
 */
const Expand = (props) => {
  let containerClass = "";
  if (props.question) {
    containerClass = props.classes || "";
  } else {
    containerClass = "col-lg-1 col-md-1 col-sm-1 " + (props.classes || "");
  }

  const toggleExapnd = () => {
    props.setCollapse(!props.collapse);
  };

  let expand;
  if (props.question) {
    expand = props.collapse ? (
      <span className={`${classes.minus}`}>&minus;</span>
    ) : (
      <span className={`${classes.plus}`}>&#43;</span>
    );
  } else {
    expand = props.collapse ? (
      <span className={classes.scaleDown}>&minus;</span>
    ) : (
      <span className={classes.expand}>&#43;</span>
    );
  }

  return (
    <div className={containerClass} onClick={toggleExapnd}>
      {expand}
    </div>
  );
};

export default Expand;
