import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import { SlideDown } from "react-slidedown";

import Expand from "../Expand/Expand";
import Paragraph from "../../components/Paragraph/Paragraph";

/**
 * RFC page references with toggle event
 * @param {*} props
 * @returns
 */
const References = (props) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <Row className="mt-1 main-container-padding">
        <Col>
          <Row className="mt-2">
            <Col lg={11} md={11} sm={11}>
              <h2 className="reference-title">
                {props.children.length > 1 ? "References" : "Reference"}
              </h2>
              <hr className="title-border-bottom" />
            </Col>
            <Expand collapse={isVisible} setCollapse={setIsVisible} />
          </Row>

          <SlideDown transitionOnAppear={false}>
            {isVisible && (
              <Paragraph rowClasses={"animate"}>
                <ol className="reference-list">{props.children}</ol>
              </Paragraph>
            )}
          </SlideDown>
        </Col>
      </Row>
    </>
  );
};

export default References;
