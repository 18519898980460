// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".About_videoImage__gi0GV {\n  width: 100%;\n}\n  ", "",{"version":3,"sources":["webpack://src/containers/About/About.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb","sourcesContent":[".videoImage {\n  width: 100%;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"videoImage": "About_videoImage__gi0GV"
};
export default ___CSS_LOADER_EXPORT___;
