// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Diagnosis_image1__2bxWp {\n    position: relative;\n    top: 0;\n    left: 0;\n    width: 100%;\n}\n\n.Diagnosis_image2__1-AAr {\n    position: absolute;\n    top: 9px;\n    left: 23px;\n    width: 92%;\n}\n\n@media only screen and (min-device-width: 320px) and (max-device-width : 768px) {\n    .Diagnosis_image2__1-AAr {\n        top: 15px;\n        left: 18px;\n    }\n    .Diagnosis_parent__2T1KF video {\n        width: 100%;\n    }\n}", "",{"version":3,"sources":["webpack://src/containers/BiploarDisorder/Diagnosis/Diagnosis.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,UAAU;AACd;;AAEA;IACI;QACI,SAAS;QACT,UAAU;IACd;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".image1 {\n    position: relative;\n    top: 0;\n    left: 0;\n    width: 100%;\n}\n\n.image2 {\n    position: absolute;\n    top: 9px;\n    left: 23px;\n    width: 92%;\n}\n\n@media only screen and (min-device-width: 320px) and (max-device-width : 768px) {\n    .image2 {\n        top: 15px;\n        left: 18px;\n    }\n    .parent video {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image1": "Diagnosis_image1__2bxWp",
	"image2": "Diagnosis_image2__1-AAr",
	"parent": "Diagnosis_parent__2T1KF"
};
export default ___CSS_LOADER_EXPORT___;
