import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import Banner from "../../components/Banner/Banner";
import Video from "../../components/Video/Video";
import Paragraph from "../../components/Paragraph/Paragraph";
import Image from "../../components/Image/Image";
import References from "../../components/References/References";
import ExternalLink from "../../components/ExternalLink/ExternalLink";

import banner from "../../assets/images/about_banner.jpg";
import mobileBanner from "../../assets/images/about_mobile.jpg";
import videoImage from "../../assets/images/Group16.png";

import classes from "./About.module.css";

const About = () => {
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    document.title = "RMS | About RMS";
  });

  // const showVideoInModal = () => {
  //   setShowVideo(true);
  // };

  return (
    <Row>
      <Col>
        <Banner banner={banner} mobileBanner={mobileBanner} />

        <Row className="margin-to-banner main-container-padding">
          <Col>
            <Row className="mt-2">
              <Col lg={8} md={8} sm={12}>
                <Paragraph>
                  <p>
                    The Rapid Mood Screener (RMS) is a screening tool that was
                    developed to differentiate bipolar I disorder from major
                    depressive disorder (MDD) in patients with depressive
                    symptoms who have been diagnosed with MDD.
                    <sup>1</sup> Created by a team of multidisciplinary experts
                    including primary care clinicians, psychiatry nurse
                    practitioners, psychiatrists, pharmacists, behavioral
                    therapists, psychometricians, and health economists, the RMS
                    provides a pragmatic approach to addressing the need for
                    easy and accurate screening of bipolar I disorder. The
                    screener was validated in a study of patients with bipolar I
                    disorder and MDD and the publication can be accessed from
                    the following{" "}
                    <ExternalLink
                      href="https://www.tandfonline.com/doi/full/10.1080/03007995.2020.1860358"
                      classes="anchor"
                    >
                      <u>journal website</u>
                    </ExternalLink>
                    .<sup>1</sup>
                  </p>
                </Paragraph>
              </Col>
              <Image
                containerClasses="col-lg-4 col-md-4 col-sm-12 mt-2"
                imageClasses={classes.videoImage}
                imagePath={videoImage}
                // clicked={showVideoInModal}
              />
              <Video showVideo={showVideo} setShowVideo={setShowVideo} />
            </Row>

            <Paragraph rowClasses="mt-3">
              <p>
                The 6-item RMS not only screens for past manic symptoms that are
                the hallmark of a bipolar I disorder diagnosis, but it also
                evaluates depressive characteristics that are more likely to
                indicate bipolar I disorder than MDD. For example, the RMS
                screens for earlier age of depression onset, response to
                antidepressant treatment, and a high number of prior depressive
                episodes since these attributes may suggest bipolar I disorder.
                <sup>2,3</sup> Additionally, the RMS includes clearly worded
                manic symptom items to help clinicians identify past mood
                elevation or elicit confirmation of a prior manic episode. The
                RMS can be completed during a clinical visit or in another
                setting (e.g., online, electronic medical record system, waiting
                room).
              </p>
            </Paragraph>
          </Col>
        </Row>

        <Paragraph>
          <References>
            <li>
              McIntyre RS, Patel MD, Masand PS, et al. The Rapid Mood Screener
              (RMS): A novel and pragmatic screener for bipolar I disorder.{" "}
              <i>Curr Med Res Opin</i>. 2021;37(1):135<span>&ndash;</span>144.
            </li>
            <li>
              Mitchell PB, Goodwin GM, Johnson GF, Hirschfeld RM. Diagnostic
              guidelines for bipolar depression: a probabilistic approach.{" "}
              <i>Bipolar Disord</i>. 2008;10(1 Pt 2):144<span>&ndash;</span>152.
            </li>
            <li>
              Hirschfeld RM. Differential diagnosis of bipolar disorder and
              major depressive disorder. <i>J Affect Disord</i>. 2014;169 Suppl
              1:S12<span>&ndash;</span>16.
            </li>
          </References>
        </Paragraph>
      </Col>
    </Row>
  );
};

export default About;
