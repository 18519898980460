// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExternalLink_anchor__3ieuU {\n    color: #007bff;\n    cursor: pointer;\n    text-decoration: underline;\n}", "",{"version":3,"sources":["webpack://src/components/ExternalLink/ExternalLink.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,0BAA0B;AAC9B","sourcesContent":[".anchor {\n    color: #007bff;\n    cursor: pointer;\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"anchor": "ExternalLink_anchor__3ieuU"
};
export default ___CSS_LOADER_EXPORT___;
