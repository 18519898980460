// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ToUrl_leavingText__aOvV- {\n    line-height: 20px !important;\n}", "",{"version":3,"sources":["webpack://src/components/ToUrl/ToUrl.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC","sourcesContent":[".leavingText {\n    line-height: 20px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leavingText": "ToUrl_leavingText__aOvV-"
};
export default ___CSS_LOADER_EXPORT___;
