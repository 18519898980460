import { useState } from "react";

import Popup from "../Popup/Popup";
import Body from "../ToUrl/Body/Body";
import Action from "../ToUrl/Action/Action";

import classes from "./ExternalLink.module.css";

/**
 * RFC for external link popup
 * @param {*} props
 * @returns <JSX />
 */
const ExternalLink = (props) => {
  const [showPopup, setShowPopup] = useState(false);

  const redirectTo = (e) => {
    setShowPopup(true);
  };

  let popup;
  if (showPopup) {
    popup = (
      <Popup
        header="You are now leaving Rapid Mood Screener"
        body={<Body toUrl={props.href} />}
        footer={<Action toUrl={props.href} back={setShowPopup} />}
        closeButton={false}
        backdrop={true}
        keyboard={false}
        show={showPopup}
        closeModal={setShowPopup}
      />
    );
  }

  const anchorClass = props.classes === "anchor" ? classes.anchor : "";

  return (
    <>
      {popup}
      <span onClick={redirectTo} className={anchorClass} id="redirect-to">
        {props.children}
      </span>
    </>
  );
};

export default ExternalLink;
