export const MOBILE_VIEW = 575;

export const RMS_QUESTION = [
  {
    id: 1,
    question: 'Have there been at least 6 different periods of time (at least 2weeks) when you felt deeply depressed?',
    answer: null,
    open: true
  }, {
    id: 2,
    question: 'Did you have problems with depression before the age of 18?',
    answer: null
  }, {
    id: 3,
    question: 'Have you ever had to stop or change your antidepressant because it made you highly irritable or hyper?',
    answer: null
  }, {
    id: 4,
    question: 'Have you ever had a period of at least 1 week during which you were more talkative than normal with thoughts racing in your head?',
    answer: null
  }, {
    id: 5,
    question: 'Have you ever had a period of at least 1 week during which you felt any of the following: unusually happy; unusually outgoing; or unusually energetic?',
    answer: null
  }, {
    id: 6,
    question: 'Have you ever had a period of at least 1 week during which you needed much less sleep than usual?',
    answer: null
  }
];