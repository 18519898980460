import React, { useState, useContext } from "react";
import { Container, Navbar } from "react-bootstrap";
import { withRouter } from "react-router";

import { GlobalContext } from "../../../state/GlobalState";

import BotTag from "./BotTag/BotTag";
import SideDrawer from "../../SideDrawer/SideDrawer";
import Navigator from "../Navigator/Navigator";
import Logo from "../Logo/Logo";

import "./Header.css";

const Header = (props) => {
  const globalState = useContext(GlobalContext);
  const [sideDrawer, setSideDrawer] = useState(false);

  const toggleSideDrawer = () => {
    setSideDrawer(!sideDrawer);
  };

  return (
    <>
      <BotTag />
      <SideDrawer sideDrawer={sideDrawer} setSideDrawer={toggleSideDrawer} />
      <div
        className={
          globalState.state.cookieEnabled
            ? "sponsor-block fixed-sponsor-block"
            : "sponsor-block"
        }
      >
        <Container className="p-1">Sponsored By AbbVie Medical Affairs</Container>
      </div>
      <Navbar
        expand="lg"
        fixed={globalState.state.cookieEnabled ? "top" : ""}
        className={globalState.state.cookieEnabled ? "top-margin" : ""}
      >
        <Container className="pr-0 pt-2 pb-2">
          <Logo clicked={() => {}} />
          <button
            type="button"
            className="navbar-toggler collapsed"
            onClick={toggleSideDrawer}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Navbar.Collapse>
            <Navigator clicked={() => {}} />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default withRouter(Header);
