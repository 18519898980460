import { Modal } from "react-bootstrap";

import "./Popup.css";

/**
 * RFC for bootstrap popup
 * @param {*} props
 * @returns
 */
const Popup = (props) => {
  const closePopup = () => {
    props.closeModal(false);
  };

  return (
    <Modal
      show={props.show}
      backdrop={props.backdrop}
      size={props.size || "lg"}
      centered
      keyboard={props.keyboard}
      onHide={closePopup}
    >
      <Modal.Header closeButton={props.closeButton} className="mt-3">
        <div className="modal-title h4 mt-2">{props.header}</div>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer className="mb-4">{props.footer}</Modal.Footer>
    </Modal>
  );
};

export default Popup;
