import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import Banner from "../../components/Banner/Banner";
import References from "../../components/References/References";
import Paragraph from "../../components/Paragraph/Paragraph";
import Symptoms from "./Symptoms/Symptoms";
import Diagnosis from "./Diagnosis/Diagnosis";

import banner from "../../assets/images/bipolar_banner.png";
import mobileBanner from "../../assets/images/bipolar_mobile.png";

const BiploarDisorder = () => {
  useEffect(() => {
    document.title = "RMS | Bipolar Disorder";
  });

  return (
    <Row>
      <Col>
        <Banner banner={banner} mobileBanner={mobileBanner} />

        <Row className="margin-to-banner main-container-padding">
          <Col>
            <Row className="mt-2">
              <Col>
                <Paragraph>
                  <p>
                    Bipolar I disorder is a chronic mood
                    disorder characterized by manic and depressive episodes.
                    <sup>1</sup> In a survey of 600 bipolar patients, approximately 70% of patients with bipolar disorder reported being initially misdiagnosed, with major depressive disorder (MDD) the most common misdiagnosis.<sup>2</sup>{" "}
                    Over half of patients with bipolar disorder (51%) who had
                    been previously misdiagnosed had a delay of 5 years or more
                    between seeking treatment and the correct diagnosis, and
                    over one third of patients (35%) had a delay of 10 years or
                    more.
                    <sup>2</sup>
                  </p>
                </Paragraph>
              </Col>
            </Row>
          </Col>
        </Row>

        <Symptoms />

        <Diagnosis />

        <References>
          <li>
            Grande I, Berk M, Birmaher B, Vieta E. Bipolar disorder.{" "}
            <i>Lancet</i>. 2016;387(10027):1561<span>&ndash;</span>1572.
          </li>
          <li>
            Hirschfeld RM, Lewis L, Vornik LA. Perceptions and impact of bipolar
            disorder: how far have we really come? Results of the national
            depressive and manic-depressive association 2000 survey of
            individuals with bipolar disorder. <i>J Clin Psychiatry</i>.
            2003;64(2):161<span>&ndash;</span>174.
          </li>
          <li>
            McIntyre RS, Calabrese JR. Bipolar depression: The clinical
            characteristics and unmet needs of a complex disorder.{" "}
            <i>Curr Med Res Opin</i>. 2019;35(11):1993<span>&ndash;</span>2005.
          </li>
          <li>
            Blanco C, Compton WM, Saha TD, et al. Epidemiology of DSM-5 bipolar
            I disorder: Results from the National Epidemiologic Survey on
            Alcohol and Related Conditions - III. <i>J Psychiatr Res</i>.
            2017;84:310<span>&ndash;</span>317.
          </li>
          <li>
            Forte A, Baldessarini RJ, Tondo L, Vazquez GH, Pompili M, Girardi P.
            Long-term morbidity in bipolar-I, bipolar-II, and unipolar major
            depressive disorders. <i>J Affect Disord</i>. 2015;178:71
            <span>&ndash;</span>78.
          </li>
          <li>
            Hirschfeld RM, Cass AR, Holt DC, Carlson CA. Screening for bipolar
            disorder in patients treated for depression in a family medicine
            clinic. <i>J Am Board Fam Pract</i>. 2005;18(4):233
            <span>&ndash;</span>239.
          </li>
          <li>
            Conus P, Macneil C, McGorry PD. Public health significance of
            bipolar disorder: implications for early intervention and
            prevention. <i>Bipolar Disord</i>. 2014;16(5):548
            <span>&ndash;</span>556.
          </li>
          <li>
            Joyce K, Thompson A, Marwaha S. Is treatment for bipolar disorder
            more effective earlier in illness course? A comprehensive literature
            review. <i>Int J Bipolar Disord</i>. 2016;4(1):19.
          </li>
          <li>
            Vieta E, Reinares M, Rosa AR. Staging bipolar disorder.{" "}
            <i>Neurotox Res</i>. 2011;19(2):279<span>&ndash;</span>285.
          </li>
          <li>
            Mitchell PB, Goodwin GM, Johnson GF, Hirschfeld RM. Diagnostic
            guidelines for bipolar depression: a probabilistic approach.{" "}
            <i>Bipolar Disord</i>. 2008;10(1 Pt 2):144<span>&ndash;</span>152.
          </li>
          <li>
            Hirschfeld RM. Differential diagnosis of bipolar disorder and major
            depressive disorder. <i>J Affect Disord</i>. 2014;169 Suppl 1:S12
            <span>&ndash;</span>16.
          </li>
        </References>
      </Col>
    </Row>
  );
};

export default BiploarDisorder;
