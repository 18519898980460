import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const Navigator = (props) => {
  return (
    <Nav onClick={props.clicked}>
      <NavLink
        className={
          "nav-link" + (window.location.pathname === "/" ? " active" : "")
        }
        activeClassName="active"
        to="/about"
      >
        About RMS<sup>&copy;</sup>
      </NavLink>

      <NavLink
        className="nav-link"
        activeClassName="active"
        to="/bipolar-disorder"
      >
        Bipolar I Disorder
      </NavLink>

      <NavLink className="nav-link" activeClassName="active" to="/development">
        Development
      </NavLink>

      <NavLink className="nav-link" activeClassName="active" to="/validation">
        Validation
      </NavLink>

      <NavLink className="nav-link" activeClassName="active" to="/rms">
        Rapid Mood Screener
      </NavLink>

      <NavLink
        className="nav-link"
        activeClassName="active"
        to="/clinician-guide"
      >
        Clinician Guide
      </NavLink>
    </Nav>
  );
};

export default Navigator;
