import React from "react";
import { Col, Row } from "react-bootstrap";

import classes from "./Banner.module.css";

/**
 * RFC for Banner/Hero Image
 * @param {*} props
 * @returns
 */
const Banner = (props) => {
  const demo = classes.bannerImage + " " + classes.desktopImg;
  return (
    <Row className={`Banner ${classes.bannerContainer}`}>
      <Col className="pl-1 pr-2">
        <img className={demo} src={props.banner} alt="BigCoDesktop" />
        <img
          className={classes.mobileImg}
          src={props.mobileBanner}
          alt="BigCoMobile"
        />
      </Col>
    </Row>
  );
};

export default Banner;
