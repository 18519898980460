import { Row, Col } from "react-bootstrap";

import classes from "../ToUrl.module.css";

const Body = (props) => {
  return (
    <div className="hcp-body">
      <Row>
        <Col>
          <p className={`${classes.leavingText}`}>
            Links to sites outside of Rapid Mood Screener are provided as a
            resource to the viewer. AbbVie Inc accepts no responsibility for the
            content of non-AbbVie linked sites.
          </p>
          <h6 className="heading-border mt-3" target="_blanck">
            Redirect to: {props.toUrl || "https://www.abbvie.com/"}
          </h6>
        </Col>
      </Row>
    </div>
  );
};

export default Body;
