// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TermsOfUse_btnSubmit__2RN3_ {\n    margin-left: 39px;\n    background-image: linear-gradient(#fe9830, #ca7116);\n    color: #fff;\n    border-color: #fff;\n    padding: 5px;\n    font-size: 18px;\n    font-weight: 600;\n    border-radius: 15px;\n    width: 200px;\n    height: 50px;\n}\n\n@media only screen and (max-width: 768px) {\n    .TermsOfUse_btnSubmit__2RN3_ {\n        margin-left: 0px;\n        width: 300px;\n        border-radius: 20px;\n    }\n    .TermsOfUse_radioContainer__2koNM {\n        text-align: center;\n        flex-wrap: nowrap;\n    }\n}", "",{"version":3,"sources":["webpack://src/components/TermsOfUse/TermsOfUse.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mDAAmD;IACnD,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI;QACI,gBAAgB;QAChB,YAAY;QACZ,mBAAmB;IACvB;IACA;QACI,kBAAkB;QAClB,iBAAiB;IACrB;AACJ","sourcesContent":[".btnSubmit {\n    margin-left: 39px;\n    background-image: linear-gradient(#fe9830, #ca7116);\n    color: #fff;\n    border-color: #fff;\n    padding: 5px;\n    font-size: 18px;\n    font-weight: 600;\n    border-radius: 15px;\n    width: 200px;\n    height: 50px;\n}\n\n@media only screen and (max-width: 768px) {\n    .btnSubmit {\n        margin-left: 0px;\n        width: 300px;\n        border-radius: 20px;\n    }\n    .radioContainer {\n        text-align: center;\n        flex-wrap: nowrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnSubmit": "TermsOfUse_btnSubmit__2RN3_",
	"radioContainer": "TermsOfUse_radioContainer__2koNM"
};
export default ___CSS_LOADER_EXPORT___;
