import { Row, Col } from "react-bootstrap";
import Paragraph from "../../Paragraph/Paragraph";

const Body = (props) => {
  return (
    <Row className="hcp-body mt-3">
      <Col>
        <Paragraph>
          <p>
            This website is intended for <b>healthcare professionals</b> in
            the United States.
          </p>
          <p>
            Please confirm that you are a <b>healthcare professional</b> in
            the US.
          </p>
        </Paragraph>
      </Col>
    </Row>
  );
};

export default Body;
