import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import Banner from "../../components/Banner/Banner";
import Paragraph from "../../components/Paragraph/Paragraph";
import References from "../../components/References/References";
import PhaseCard from "../../components/PhaseCard/PhaseCard";

import banner from "../../assets/images/development_banner.jpg";
import mobileBanner from "../../assets/images/development_mobile.jpg";
import phase1 from "../../assets/images/phase1.png";
import phase2 from "../../assets/images/phase2.png";

import classes from "./Development.module.css";

const Development = () => {
  const [showPhase1Popup, setShowPhase1Popup] = useState(false);
  const [showPhase2Popup, setShowPhase2Popup] = useState(false);

  useEffect(() => {
    document.title = "RMS | Development";
  });

  const showPhaseHandler = (phaseNumber) => {
    if (phaseNumber === 1) {
      setShowPhase1Popup(!showPhase1Popup);
      setShowPhase2Popup(false);
    } else {
      setShowPhase2Popup(!showPhase2Popup);
      setShowPhase1Popup(false);
    }
  };

  return (
    <Row>
      <Col>
        <Banner banner={banner} mobileBanner={mobileBanner} />

        <Row className="margin-to-banner main-container-padding">
          <Col>
            <Paragraph>
              <p className="mt-2">
                The rapid mood screener was developed and validated in a 3-step process with the
                goal of creating a pragmatic and accurate tool that
                screens for bipolar I disorder.<sup>1</sup> This
                clinician-administered tool was designed to screen for bipolar
                depression features and manic symptoms using the fewest number
                items possible while maintaining strong test characteristics.
              </p>
            </Paragraph>
          </Col>
        </Row>

        <Row className="main-container-padding mb-5">
          <Col lg={5} md={5} sm={12} className="text-center position-relative">
            {showPhase1Popup && (
              <PhaseCard
                phaseNumber={1}
                clicked={showPhaseHandler.bind(this, 1)}
              >
                <h6 className="phase-heading">
                  Concept and Item Development
                </h6>
                <ul>
                  <li>
                    Targeted literature search
                  </li>
                  <li>
                    Two Delphi consensus panels with multidisciplinary group of clinical experts
                  </li>
                  <li>
                    Ten draft screener items developed for qualitative and quantitative testing
                  </li>
                </ul>
              </PhaseCard>
            )}

            <div>
              <img
                className="phase-image"
                src={phase1}
                alt="phase1"
                onClick={showPhaseHandler.bind(this, 1)}
              />
              {/* {!showPhase1Popup && ( */}
              <span
                className={`${classes.phase1Circle1}`}
                onClick={showPhaseHandler.bind(this, 1)}
              >
                <span className={`${classes.phaseText1}`}>Phase</span> <br />
                <span className={`${classes.phaseNumber1}`}>1</span>
              </span>
              {/* )} */}
              <span className={`${classes.phase1circle2}`}></span>
              <span className={`${classes.phase1circle3}`}></span>
            </div>
          </Col>
          <Col lg={7} md={7} sm={12} className="align-content">
            <p className="mt-1 mb-3">
              RMS development began with a literature search conducted
              to identify concepts that could differentiate bipolar I disorder
              from MDD <b>(Phase 1)</b>.
            </p>
          </Col>
        </Row>

        <hr className={classes.horizontalLine} />

        <Row className="main-container-padding mt-4">
          <Col lg={5} md={5} sm={12} className="text-center position-relative">
            {showPhase2Popup && (
              <PhaseCard
                phaseNumber={2}
                clicked={showPhaseHandler.bind(this, 2)}
              >
                <h6 className="phase-heading">
                  Qualitative Interviews with Patients{" "}
                  <br className="custom-break" />
                </h6>
                <ul>
                  <li>
                    Wording of items refined based on cognitive debriefing 
                    interviews with individuals with MDD or bipolar I disorder, 
                    ensuring item understanding and interpretation (n = 12)
                  </li>
                </ul>
              </PhaseCard>
            )}
            <div>
              <img
                className="phase-image width-phase2"
                src={phase2}
                alt="phase2"
                onClick={showPhaseHandler.bind(this, 2)}
              />
              <span className={`${classes.phase2circle2}`}></span>
              <span
                className={`${classes.phase2Circle1}`}
                onClick={showPhaseHandler.bind(this, 2)}
              >
                <span className={`${classes.phaseText2}`}>Phase</span> <br />
                <span className={`${classes.phaseNumber2}`}>2</span>
              </span>
              <span className={`${classes.phase2circle3}`}></span>
              <span className={`${classes.phase2circle4}`}></span>
            </div>
          </Col>
          <Col lg={7} md={7} sm={12}>
            <p className="mb-3 mt-3">
              A multidisciplinary team of mental health and psychometric experts
              identified the concepts that were the most discriminating for
              bipolar I disorder. Using these concepts and manic symptom
              indicators, the development team drafted an initial group of items
              that were anticipated to differentiate patients with bipolar I
              disorder from those with MDD. These draft items were evaluated in
              cognitive debriefing interviews with adults who had a diagnosis of
              bipolar I disorder or MDD <b>(Phase 2)</b>.
            </p>
          </Col>
        </Row>

        <Paragraph rowClasses="mt-3 main-container-padding">
          <p>
            Based on insights from the interview participants, modifications
            were made to the draft items to improve clarity, refine
            patient-friendly wording, and support content validity. Ten draft
            screener items were retained for quantitative evaluation and
            validation.
          </p>
        </Paragraph>
        <Paragraph rowClasses="mt-2 main-container-padding">
          <p className="phase-info">
            *BP-I, bipolar I disorder; MDD, major depressive disorder.
          </p>
        </Paragraph>

        <References>
          <li>
            McIntyre RS, Patel MD, Masand PS, et al. The Rapid Mood Screener
            (RMS): A novel and pragmatic screener for bipolar I disorder.{" "}
            <i>Curr Med Res Opin</i>. 2021;37(1):135<span>&ndash;</span>144.
          </li>
        </References>
      </Col>
    </Row>
  );
};

export default Development;
