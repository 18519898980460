import { Row, Col } from "react-bootstrap";

/**
 * RFC for creating bootstrap column
 * @param {*} props
 * @returns
 */
const Paragraph = (props) => {
  return (
    <Row className={props.rowClasses || ""}>
      <Col className={props.colClasses || ""}>{props.children}</Col>
    </Row>
  );
};

export default Paragraph;
