import classes from "./Backdrop.module.css";

/**
 * React functional component for back drop for slide menu in mobile device
 * @param {*} props
 * @returns <JSX />
 */
const Backdrop = (props) =>
  props.show && (
    <div
      id="backdrop"
      className={classes.Backdrop}
      onClick={props.clicked}
    ></div>
  );

export default Backdrop;
