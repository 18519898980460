// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button.NotFound_nfBtn__c3CZb {\n    padding: 8px 15px 8px 15px;\n    font-family: \"NeueHaasUnicaW01-Light\";\n    width: auto;\n    background-color: #FF8307;\n    border-color: #FF8307;\n    border-radius: 10px;\n    font-weight: 500;\n}\n\nbutton.NotFound_nfBtn__c3CZb:hover {\n    background-color: #FF8307;\n    border-color: #FF8307;\n}\n\nbutton.NotFound_nfBtn__c3CZb a:hover {\n    text-decoration: none;\n}\n\nh1 {\n    font-family: \"NeueHaasUnicaW02-Bold\";\n    color: #071D49;\n    font-size: 31px;\n}\n\nbutton .NotFound_navLink__2_Osw {\n    color: #fff;\n}\n\n.NotFound_verticalAlign__fKPyp {\n    margin: 113px 0px;\n}\n\n.NotFound_verticalAlign__fKPyp p {\n    font-family: \"NeueHaasUnicaW01-Light\";\n    font-size: 16px;\n    margin: 20px 0px 20px 0px;\n}", "",{"version":3,"sources":["webpack://src/components/NotFound/NotFound.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,qCAAqC;IACrC,WAAW;IACX,yBAAyB;IACzB,qBAAqB;IACrB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,oCAAoC;IACpC,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,qCAAqC;IACrC,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":["button.nfBtn {\n    padding: 8px 15px 8px 15px;\n    font-family: \"NeueHaasUnicaW01-Light\";\n    width: auto;\n    background-color: #FF8307;\n    border-color: #FF8307;\n    border-radius: 10px;\n    font-weight: 500;\n}\n\nbutton.nfBtn:hover {\n    background-color: #FF8307;\n    border-color: #FF8307;\n}\n\nbutton.nfBtn a:hover {\n    text-decoration: none;\n}\n\nh1 {\n    font-family: \"NeueHaasUnicaW02-Bold\";\n    color: #071D49;\n    font-size: 31px;\n}\n\nbutton .navLink {\n    color: #fff;\n}\n\n.verticalAlign {\n    margin: 113px 0px;\n}\n\n.verticalAlign p {\n    font-family: \"NeueHaasUnicaW01-Light\";\n    font-size: 16px;\n    margin: 20px 0px 20px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nfBtn": "NotFound_nfBtn__c3CZb",
	"navLink": "NotFound_navLink__2_Osw",
	"verticalAlign": "NotFound_verticalAlign__fKPyp"
};
export default ___CSS_LOADER_EXPORT___;
