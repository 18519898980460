const Image = (props) => {
  return (
    <div className={props.containerClasses}>
      <img
        width={props.width}
        className={props.imageClasses}
        src={props.imagePath}
        alt={props.alt}
        onClick={props.clicked}
      />
    </div>
  );
};

export default Image;
