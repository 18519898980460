import { NavLink } from "react-router-dom";

import logo from "../../../assets/images/logo.svg";

import classes from "./Logo.module.css";

const Logo = (props) => (
  <NavLink to="/" className="logo" onClick={props.clicked}>
    <img className={classes.desktopLogo} src={logo} alt="RMS Logo" />
    <img className={classes.mobileLogo} src={logo} alt="RMS Logo" />
  </NavLink>
);

export default Logo;
