import { createContext, useReducer } from "react";

import { getCookie } from "../helpers/Cookie";

import GlobalReducer from "./GlobalReducer";

export const GlobalContext = createContext();

const USER_NAME = process.env.REACT_APP_USER_NAME;
const PASSWORD = process.env.REACT_APP_PASSWORD;

const initialState = {
  isHcp: getCookie("isHcp"),
  cookieEnabled: getCookie("cookieEnabled"),
  isLoggedIn: true, //getCookie("_log_rms_in"),
  error: false,
  loading: false,
  errorMessage: "",
};

const buff = new Buffer(process.env.REACT_APP_API_TOKEN, "base64");
const base64ToStringNew = buff.toString("ascii");
const requestOptions = {
  method: "POST",
  headers: new Headers({
    token: base64ToStringNew,
  }),
  redirect: "follow",
};

export const GlobalState = (props) => {
  const [state, dispatch] = useReducer(GlobalReducer, initialState);

  const setSpeciality = async (speciality) => {
    dispatch({
      type: "ADD_HCP",
    });
    /*
    dispatch({
      type: "SET_LOADER",
    });

    const requestParams = {
      ...requestOptions,
      body: new URLSearchParams({
        speciality: speciality,
      }),
    };

    try {
      const response = await fetch("/api/hcp_entries", requestParams);
      const result = await response.json();
      if (result.status === 200) {
        dispatch({
          type: "ADD_HCP",
        });
      } else {
        dispatch({
          type: "SET_ERROR",
          message: result.message,
        });
      }
    } catch (err) {
      dispatch({
        type: "SET_ERROR",
        // message: "Something went wrong. Please try again later!",
      });
    }
    */
  };

  const loginUser = async ({ userName, password }) => {
    dispatch({
      type: "SET_LOADER",
    });
    // const requestParams = {
    //   ...requestOptions,
    //   body: new URLSearchParams(user),
    // };
    // const response = await fetch("http://localhost:9090/login", requestParams);
    // const result = await response.json();

    if (userName === USER_NAME && password === PASSWORD) {
      dispatch({
        type: "LOGIN_USER",
        userName,
      });
    } else {
      dispatch({
        type: "LOGIN_FAIL",
        message: "User Name/Password is invalid",
      });
    }
  };

  const acceptCookie = () => {
    dispatch({ type: "ENABLE_COOKIE" });
  };

  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatchActions: {
          loginUser,
          setSpeciality,
          acceptCookie,
        },
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
