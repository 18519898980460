import { Row, Col, Table } from "react-bootstrap";
import Paragraph from "../../../components/Paragraph/Paragraph";

import classes from "./Characteristics.module.css";

const Characteristics = (props) => {
  return (
    <Row className="mt-3 main-container-padding">
      <Col>
        <Paragraph className="">
          <p className="padding mb-3">
            The test characteristics of final 6 RMS items and the MDQ were
            evaluated in the same study population.<sup>1</sup>
          </p>
        </Paragraph>

        <Row className="rms-items">
          <Col>
            <Table className={classes.characteristics}>
              <thead>
                <tr data-aos="fade-up">
                  <th>Tool</th>
                  <th>Sensitivity</th>
                  <th>Specificity</th>
                  <th>Correctly Predicted Events</th>
                </tr>
              </thead>
              <tbody>
                <tr data-aos="fade-up">
                  <td>
                    Rapid Mood Screener (RMS)<sup>a</sup>
                  </td>
                  <td className="text-center">0.88</td>
                  <td className="text-center">0.80</td>
                  <td className="text-center">83.61</td>
                </tr>
                <tr data-aos="fade-up">
                  <td>
                    Mood Disorder Questionnaire (MDQ)<sup>b</sup>
                  </td>
                  <td className="text-center">0.86</td>
                  <td className="text-center">0.78</td>
                  <td className="text-center">81.97</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        {props.children}
      </Col>
    </Row>
  );
};

export default Characteristics;
