import React, { useState, useContext } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

import Popup from "../Popup/Popup";
import { Spinner } from "react-bootstrap";
import { GlobalContext } from "../../state/GlobalState";

export default function LoginForm() {
  const { state, dispatchActions } = useContext(GlobalContext);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    dispatchActions.loginUser({ userName, password });
  };

  const header = <h5>Please login to continue</h5>;
  const body = (
    <Row className="justify-content-md-center">
      <Col sm={6} md={6}>
        <Form>
          {state.error && (
            <div style={{ color: "red" }}>
              <h6>{state.errorMessage}</h6>
            </div>
          )}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>User Name</Form.Label>
            <Form.Control
              type="email"
              placeholder="User Name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Text className="text-light">
            We'll never share your login details with anyone else.
          </Form.Text>
          <Button
            variant="primary"
            type="submit"
            style={{ width: "100%", backgroundColor: "#FF8307", color: "#fff" }}
            onClick={handleLogin}
          >
            Login
          </Button>
          {state.loading && (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </Form>
      </Col>
    </Row>
  );
  return (
    <div>
      <Popup
        show
        size="xs"
        header={header}
        body={body}
        keyboard={false}
        closeButton={false}
        backdrop="static"
      />
    </div>
  );
}
